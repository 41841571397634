@import '../themes/functions.scss';

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(color(black), .44);
    opacity: 0;
}

.wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 2.75rem 2.2rem;
    padding: 1.75rem 2.2rem;
    width: 30rem;
    // height: 32rem;
    height: fit-content;
    background-color: rgba(color(white), .45);
    border-radius: var(--modal-border);
    backdrop-filter: blur(7px);
    opacity: 0;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        h2 {
            font-weight: 700;
            font-size: 1.2rem;
        }

        button {
            $size: 2.5rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: $size;
            height: $size;
            border-radius: var(--gentle-border);
            background-color: rgba(color(white), .6);
            box-shadow: var(--soft-shadow);
        }
    }

    .body {
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;
        $page-width: 25.6rem;
        // overflow: hidden;
        // min-height: calc((var(--field-height) + .9rem) * 5);
        // font-weight: 500;

        .formWrap {

            position: relative;
            overflow-x: hidden;

            .form {
                position: absolute;
                display: flex;
                // height: calc((var(--field-height) + .9rem) * 4);
                // overflow: hidden;
    
                .page {
                    display: block;
                    box-sizing: content-box;
                    position: relative;
                    top: 0;
                    width: $page-width;
                    margin-right: 4rem
                }

                .page:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .footer {
        justify-self: flex-end;

        .centerBtn {
            display: flex;
            justify-content: center;
        }

        .gridBtn {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 1rem;
        }

        .magicWrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pageNav {
                display: inline-flex;

                .pageDot {
                    $dotSize: .7rem;
                    display: block;
                    background: color(white);
                    width: $dotSize;
                    height: $dotSize;
                    border-radius: 50%;
                    margin-right: calc($dotSize * .5)
                }

                .pageDot:last-child {
                    margin-right: 0;
                }

                .currentPage {
                    background-color: color(black);
                }
            }

            .btnWrap {
                display: flex;

                .formBtn {
                    margin-left: 1rem;
                }
            }
        }
    }
}

.reveal {
    opacity: 1;
}

.stealth {
    display: none;
}

$mobile-breakpoint: 768px;
@media (max-width: $mobile-breakpoint) {
    // .wrap {
    //     width: 100%;
    //     margin: 0 10vw;

    //     .body {
    //         width: 100%;

    //         .page {
    //             width: 100%;
    //         }
    //     }
    // }
}