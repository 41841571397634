.main {
    position: relative;
    min-height: 100%;
}

.wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
}

.right {
    position: relative;
    background-color: var(--grey-light);
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    padding: var(--page-top-pad) 2.5rem;
    border-radius: var(--page-border) 0 0 var(--page-border);
    overflow: hidden;
    box-shadow: -.2rem 0 .8rem rgba(#000, .02);
    padding-bottom: 2.5rem;
    // background: #F0F2F5;

    .bgImg {
        position: absolute;
        right: 0;
        top: -4rem;
        opacity: .3;
        filter: blur(130px);
        z-index: 0;
    }
}

.header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin-bottom: 1.75rem;
    z-index: 300;

    .title {
        font-weight: 700;
        font-size: calc(2rem * 0.78);
        padding-left: var(--indent1);
    }

    .rightWrap {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .search {
            margin-bottom: 0;
        }

        .userBtn {
            $size: 3.6rem;

            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: $size;
            // height: $size;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 2rem;
            cursor: pointer;

            img {
                width: calc($size * 1.1);
            }
        }
    }

    .name {
        font-weight: 500;
        font-size: 1rem;
        // width: 17rem;
    }
}

.titleWrap {
    display: inline-flex;
    flex-direction: row;

    .dropDownWrap {
        width: 14rem;
        margin-left: 2rem;
        margin-top: -.3rem;
    }
}

.loadingView {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
}

$breakpoint-mobile: 768px;
@media (max-width: $breakpoint-mobile) {
    .wrap {
        padding-left: 0;
    }

    .right {
        // background-color: pink;
        width: 100vw;
        padding: 7rem 1.5rem 2rem 1.5rem;
        overflow: hidden;
        border-radius: 0;
    }

    .header {

        margin-bottom: 3rem;

        .titleWrap {
        
            .title {
                font-size: calc(1.4rem);
                padding-left: 0;
            }

            .dropDownWrap {
                // display: none;
            }
        }

        .name {
            display:none
        }
    }
}