.wrap {
    display: flex;
    flex-direction: column;
}

.imgWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    justify-self: center;
    overflow: hidden;
    margin-bottom: 2rem;
    background-color: var(--grey-light);
    width: 9rem;
    height: 9rem;
    overflow: hidden;
    padding: 1rem;
    border-radius: 50%;

    img {
        width: 100%;
    }
}

.topWrap {
    flex-grow: 1;

    .imgOuterWrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.bottomWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 100%;
    flex-grow: 4;
}

.drop1 {
    z-index: 999;
}