@import '../themes/functions';

.controls {
    display: flex;
    justify-content: space-between;

    .filter {
        width: 10rem;
    }

    .overlayTrigger {
        background-color: color(frenchSkyBlue);
    }
}

.tableWrap {
    $first-pad: 2.5rem;
    $header-height: 3.5rem;
    background-color: color(paneStrong);
    backdrop-filter: blur(100px);
    width: 100%;
    height: calc(100vh - 28vh);
    border-radius: var(--table-border);
    box-shadow: var(--super-soft-shadow);
    padding: 2rem 2rem;
    overflow: scroll;

    .table {
        width: 100%;
        font-size: 15px;
        table-layout: fixed;

        .header {
            height: $header-height;

            th {
                background-color: rgba($color: color(softBlue), $alpha: .2);
                color: rgba($color: color(black), $alpha: .5);
                font-weight: 500;
                text-align: left;

                .delBtn {
                    // background-color: var(--red);
                    background-color: #cf0000;
                }
            }

            th:first-child {
                border-radius: var(--table-border) 0 0 0;
                padding-left: $first-pad;
            }

            th:last-child {
                border-radius: 0 var(--table-border) 0 0;
            }
        }

        th.imageHeader {
            color: rgba(0,0,0,0);
        }

        .bodyInner {
            width: 100%;
            overflow: scroll;
            // height: 100%;
        }

        .tableRow {
            $row-height: 4.6rem;
            border-bottom: solid 1px rgba(#000, .05);
            overflow: hidden;

            .imageTD div {
                $img-size: 3rem;
                background-color: color(pastelBlue);
                width: $img-size;
                height: $img-size;
                border-radius: 50%;
                overflow: hidden;
                padding: .4rem;
                box-shadow: 0 0 3.125rem rgba(0,0,0, 0.05);
            }

            .dataWrap {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // overflow: hidden;
                height: $row-height;
            }

            .textDataWrap {
                height: 1.3rem;
                overflow: hidden;
            }

            .timeDataWrap {

                .bubble {
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: color(softPurple);
                    padding: .3rem .8rem;
                    border-radius: var(--gentle-border);
                    // margin-left: -.7rem;
                }
            }

            td {
                font-weight: 500;
                font-size: 1rem;
                padding-right: 4rem;
            }
            
            td:first-child {
                padding-left: $first-pad;
            }

            td:last-child {
                text-align: end;
                // padding-right: 0;
            }

            .crudWrap {
                $btn-size: 2rem;
                $btn-margin: .5rem;
                width: ($btn-size * 3) + (2 * $btn-margin) + .5rem;
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;

                .crudBtn {
                    display: inline-flex;
                    background-color: rgba($color: color(pastelBlue), $alpha: .2);
                    width: $btn-size;
                    height: $btn-size;
                    border-radius: 50%;
                    margin-right: .5rem;
                    cursor: pointer;
                    padding: .5rem;
                    color: color(pastelBlue)
                }

                .crudBtn:last-child {
                    margin-right: 0;
                }
            }
        }

        .tableRow:last-child {
            border-bottom: none;
        }
    }
}


$breakpoint-mobile: 768px;
@media (max-width: $breakpoint-mobile) {

    .controls {
        display: flex;
        justify-content: space-between;
    
        .overlayTrigger {
            background-color: color(frenchSkyBlue);
        }
    }

    .bigWrap {
        position: relative;

        .innerWrap {
            // width: calc(200% + 2 * 1.5rem);
            width: 200vw;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 6rem;
            justify-content: space-between;
            position: absolute;
            transition: all .3s ease;

            .mobileItem {

                $first-pad: 2.5rem;
                $header-height: 3.5rem;
                background-color: color(paneStrong);
                backdrop-filter: blur(100px);
                width: 100%;
                height: 67vh;
                border-radius: var(--table-border);
                box-shadow: -.1rem 0 .8rem rgba(#000, .05);
                padding: 2rem 2rem;
                overflow: scroll;

                .topWrap {
                    display: flex;
                    justify-content: flex-end;

                    button {
                        $size: 2.5rem;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: $size;
                        height: $size;
                        border-radius: var(--gentle-border);
                        // background-color: rgba(color(white), .6);
                        background-color: rgba(black, .1);
                        box-shadow: var(--soft-shadow);
                        padding: .2rem;
    
                        img {
                            object-fit: contain;
                            width: 50%;
                            // margin-left: -10%;
                        }
                    }
                }

                .imgOuterWrap {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    .imgWrap {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-self: center;
                        justify-self: center;
                        overflow: hidden;
                        margin-bottom: 2rem;
                        background-color: var(--grey-light);
                        width: 9rem;
                        height: 9rem;
                        overflow: hidden;
                        padding: 1rem;
                        border-radius: 50%;
                    
                        img {
                            width: 100%;
                        }
                    }
                }

                .mobileField {
                    border-radius: var(--txt-border);
                    padding: var(--drop-pad);
                    background-color: rgba(color(frenchSkyBlue), .2);
                    margin-bottom: 1rem;

                    .title {
                        font-weight: 500;
                    }

                    .value {

                    }
                }
    
                .crudWrap {
                    margin-top: 2.5rem;
                }
            }
        }

        .inactiveItem {
            left: 0;
        }

        .activeItem {
            right: 0;
        }
    }
    
    .crudWrap {
        $btn-size-og: 2rem;
        $btn-margin-og: .5rem;
        width: ($btn-size-og * 3) + (2 * $btn-margin-og) + .5rem;
        position: relative;

        .crudBtn {
            display: inline-flex;
            background-color: rgba($color: color(pastelBlue), $alpha: .2);
            width: $btn-size-og;
            height: $btn-size-og;
            border-radius: 50%;
            margin-right: .5rem;
            cursor: pointer;
            padding: .5rem;
            color: color(pastelBlue)
        }

        .crudBtn:last-child {
            margin-right: 0;
        }
    }
    
    .tableWrap {
        $first-pad: 2.5rem;
        $header-height: 3.5rem;
        background-color: color(paneStrong);
        backdrop-filter: blur(100px);
        width: 100%;
        height: 67vh;
        border-radius: var(--table-border);
        box-shadow: -.1rem 0 .8rem rgba(#000, .05);
        padding: 2rem 2rem;
        margin-right: 1.5rem;
        overflow: scroll;
    
        .table {
            width: 100%;
            font-size: 15px;
            table-layout: fixed;
    
            .header {
                display: none;
            }
    
            .bodyInner {
                width: 100%;
                overflow: scroll;
                // height: 100%;
            }
    
            .tableRow {
                $row-height: 4.6rem;
                border-bottom: solid 1px rgba(#000, .05);
                overflow: hidden;
                display: flex;
                align-items: center;
                position: relative;
                padding-right: (2rem * 2) + (2 * .5rem) + .5rem;
                // justify-content: space-between;

                .imageTD {
                    width: 3rem;
                    padding-right: 1rem;
                    box-sizing: content-box;
                }
    
                .imageTD div {
                    // width: fit-content;
                }
    
                .dataWrap {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: $row-height;
                }
    
                .textDataWrap {
                    height: 1.3rem;
                    overflow: hidden;
                }
    
                .timeDataWrap {
    
                    .bubble {
                        height: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: color(softPurple);
                        padding: .3rem .8rem;
                        border-radius: var(--gentle-border);
                        // margin-left: -.7rem;
                    }
                }
    
                td {
                    width: fit-content;
                    padding-right: 1rem;
                }
                
                td:first-child {
                    padding-left: 0;
                }
    
                td:last-child {
                    padding-right: 0;
                }
    
                .crudWrap {
                    $btn-size: 2rem;
                    $btn-margin: .5rem;
                    width: ($btn-size * 2) + (2 * $btn-margin) + .5rem;
                    position: absolute;
                    right: 0;
                    top: calc(50% - $btn-size / 2);

                    .crudBtn:first-child {
                        display: none;
                    }
                }
            }
    
            .tableRow:last-child {
                border-bottom: none;
            }
        }
    }
}