@import '../themes/functions';

.wrap {
    height: var(--field-height);
    width: 100%;
    background-color: color(paneSoft);
    border-radius: var(--txt-border);
    // border-width: .1rem;
    // border-color: rgba($color: #000000, $alpha: 0);
    padding: .9rem .8rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.8rem;
    box-shadow: var(--super-soft-shadow-small);
}

.inline {
    display: inline-flex;
    width: calc(50% - .5rem)
}

.col1 {
    margin-right: 1rem;
}

.col2 {
    margin-right: 0;
}

.img {
    height: 100%;
}

.input {
    width: 100%;
    background: none;
    border: 0;
    font-family: 'AzoSans';
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}

.input:focus {
    outline: none;
    box-shadow: none;
}

.iconWrap {
    width: 1.6rem;
    display: flex;
    justify-content: center;
    height: 1rem;
}

.email {
    padding: .1rem 0;
}

.focus {
    border-color: #89CFF0;   
}

.invalid {
    background-color: color(paneRedSoft);
    // border-color: var(--red);
}

.bigBox {
    height: 15rem;
}

.multiline {
    height: 100%;
}

@media (max-width: 768px) {
    .wrap {
        padding: .2rem .8rem;
        align-items: center;
        margin-top: .2rem;
    }
}