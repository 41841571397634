.restockTitles {
    font-weight: 700;
    margin: 2.5rem 0 2rem 0;
    padding: 0 var(--indent2)
}

.restockTable {
    display: grid;
    grid-template-columns: 1.5fr 5fr 3fr 2.5fr 2.5fr 1.5fr 4fr;
    column-gap: 2rem;
    align-items: center;
}

.restockFeed {

    .restockItem {
        background: white;
        border-radius: var(--round2);
        margin-bottom: 1rem;
        padding: .75rem var(--indent2);
        font-weight: 500;

        .iconWrap {
            display: grid;
            grid-template-columns: repeat((2, 1fr));
            height: 1.3rem;
            // padding: .7rem 0;
            border-left: 1.5px solid var(--black-light);
            padding-left: 3rem;
            // background-color: blue;

            .icon {
                height: 100%;
                min-height: 100%;
                cursor: pointer;
            }

            .icon:hover {
                opacity: .5;
            }
        }
    }

    .imgWrap {
        height: 4rem;
        width: 4rem;
        background: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: .4rem;

        img {
            width: fit-content;
            max-width: 125%;
        }
    }
}

$breakpoint-mobile: 768px;
@media (max-width: $breakpoint-mobile) {
    .restockTitles {
        display: none;
    }

    .restockFeed {

        height: fit-content;

        .restockTable {
            grid-template-columns: 1fr 6fr;
            column-gap: 1rem;
        }

        .restockItem {
            .col {
                display: none;
            }
        
            .titleCol {
                display: block;
                // background-color: pink;
                width: 100%;
            }
        
            .iconWrap {
                display: none;
            }
        
            .iconWrap3 {
                display: none;
            }
        }
    }

    .feedSpacing {
        height: 6.5rem;
    }

    .feedWrap {
        position: absolute;
        display: flex;
        flex-direction: row;
        // background-color: pink;
        width: 200vw;
        left: -1.5rem;
        margin-bottom: 100vh;
    }

    .feedSlideTwo {
        left: calc(-1.5rem - 100vw);
    }

    .feedSlide {
        padding: 0 1.5rem;
        width: 100vw;
        // height: 100vh;
    }

    .slideTwo {

        .itemDataWrap {

            background-color: white;
            width: 100%;
            min-height: 50vh;
            border-radius: var(--round2);
            padding: 3rem 1.75rem 0 1.75rem;

            .itemCloseWrap {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: -1.5rem;
                margin-bottom: .5rem;

                p {
                    font-weight: bold;
                    font-size: 1.5rem;
                    cursor: pointer;
                }
            }

            .itemDataTop {
                // height: 8rem;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 2rem;

                .itemImgWrap {
                    display: inline-block;
                    width: 8rem;
                    // height: 8rem;
                    // background-color: var(--grey);
                    
                    border-radius: 50%;
                }
            }

            .itemDataBottom {
                // height: fit-content;
                display: block;
                // height: 20vh;
                width: 100%;
                background-color: var(--grey-light);
                border-radius: var(--round2);
                padding: 1.75rem;
                font-weight: 500;
                line-height: 2rem;

                .itemInfo {
                    font-weight: 400;
                    margin-bottom: 1rem;
                    // background-color: #D9D9D9;
                    // border: 1px solid var(--black-light);
                    // padding: .5rem;
                    border-radius: var(--round2);
                }
            }

            .itemBtnWrap {

                margin-top: 2.3rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                
                .itemBtn {
                    display: inline-flex;
                    padding: 1rem;
                    flex: column;
                    justify-content: center;
                    align-items: center;
                    // background-color: var(--grey-light);
                    background-color: #D9D9D9;
                    height: 5rem;
                    width: 5rem;
                    border-radius: 2rem 2rem 0 0;
                    margin-left: 1rem;

                    .itemBtnImg {
                        height: 1.5rem;
                    }

                    .eyeIcon {
                        height: 1.3rem;
                    }
                }
            }
        }
    }
}