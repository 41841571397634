@import '../themes/functions';

.wrap {
    font-size: 1rem;
    font-weight: 500;
    color: var(--grey);
    position: relative;
    z-index: 998;
}

.topWrap, .itemWrap {
    box-shadow: 0 .1rem .6rem rgb(#000, .03);
}

.topWrap {
    height: var(--field-height);
    width: 100%;
    background-color: color(paneSoft);
    border-radius: var(--txt-border);
    padding: var(--drop-pad);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.8rem;
    box-shadow: var(--super-soft-shadow-small);
    cursor: pointer;

    .left {
        width: 97%;
        display: flex;
        align-items: center;
        color: var(--grey);

        .iconWrap {
            height: 100%;
            width: 1rem;
            margin-right: 1.2rem;
    
            .icon {
                height: 100%;
            }
        }
    }

    .rotate {
        transform: rotate((90deg));
    }
}

.itemWrap {
    position: absolute;
    background-color: color(paneSoft);
    backdrop-filter: blur(10px);
    width: 100%;
    margin-top: -1rem;
    border-radius: var(--round2);
    overflow: hidden;
    
    .item {
        padding: var(--drop-pad);
    }
    
    .item:hover {
        background-color: color(paneStrong);
        cursor: pointer;
    }
}

.white {
    .topWrap {
        background-color: white;
        box-shadow: 0px .1rem .2rem rgba(0,0,0, .2);
        margin-bottom: 0;
        align-items: center;


        .iconWrap {
            height: 1.3rem;
            width: auto;
            margin-right: 1.2rem;
    
            .icon {
                height: 100%;
            }
        }

        .arrow {
            height: 1.2rem;
        }
    }

    .itemWrap {
        margin-top: .5rem;
        border-radius: var(--round);
        box-shadow: 0px .1rem .2rem rgba(0,0,0, .2);
        background: white;
    }
}