.versionWrap {
    height: var(--field-height);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: .6rem 0;
    
    .left {
        width: fit-content;

        .advancedWrap {
            display: flex;
            flex-direction: row;

            .txtWrap {
                width: 50%;
                margin-right: 1rem;
            }

            .versionNo {
                margin-right: 1rem;
            }
        }

        .simpleWrap {
            display: inline-block;
            width: 8rem;
        }
    }

    .right {
        p {
            height: 100%;
            cursor: pointer;
            font-weight: 500;
            padding-right: 1rem;
            // text-decoration: underline;
            transition: .3s ease-in;
        }

        p:hover {
            opacity: .7;
        }
    }
}

.loadingWrap {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--modal-border);
    backdrop-filter: blur(20px);


    img {
        border-radius: var(--modal-border);
        width: 7rem
    }
}