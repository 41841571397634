.textInput {
    margin-top: .8rem;
}

.imgWrap {
    display: flex;
    height: 10rem;
    width: 10rem;
    background-color: var(--grey-light);
    border-radius: var(--round2);
    padding: 1rem;
    justify-content: center;
    align-items: center;
    margin-bottom: .8rem;
    overflow: hidden;
    border: none;
    cursor: pointer;

    .img {
        height: 100%;
    }
}

.imgWrap:hover {
    opacity: .4;
}

.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}