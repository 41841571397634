@import '../themes/functions';

.wrap {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: color(paneSoft);
    padding: 1.8rem;
    border-radius: var(--round2);
    box-shadow: var(--super-soft-shadow);
    backdrop-filter: var(--blur);

    p {
        width: fit-content;
    }
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.value {
    font-size: calc(5rem * 0.6);
    font-weight: 700;
    margin-bottom: .3rem;
}

.label {
    font-size: 1.5rem;
    font-weight: 700;
    // color: var(--grey-medium);
    color: var(--black-light);
    opacity: .8;
    text-align: center;
}

.valueBalancer {
    margin-right: 1rem;
}

.triWrap {
    width: fit-content;
    margin-left: 1rem;
}

.triangle {
    stroke-width: 1;
}

.upTri {
    fill: var(--green);
    stroke: var(--green);
}

.downTri {
    fill: var(--red);
    stroke: var(--red);
}

$mobile-breakpoint: 768px;
@media (max-width: $mobile-breakpoint) {

    .wrap {
        padding: .6rem .2rem;
    }

    .value {
        font-size: calc(2.5rem * 0.78);
        font-weight: 700;
    }

    .label {
        font-size: 1rem;
    }

    .valueBalancer {
        margin-right: 0rem;
    }

    .triWrap {
        width: fit-content;
        margin-left: .4rem;
    }

    .triangle {
        scale: .7;
    }
}