.dropDownWrap {
    width: 14rem;
}

.section {

    margin-top: 8rem;

    .titleWrap {
        display: flex;
        justify-content: center;

        .title {
            background-color: rgba(255,255,255, .6);
            width: fit-content;
            min-width: 17rem;
            text-align: center;
            padding: 0.45rem 2rem;
            margin-bottom: 4rem;
            border-radius: var(--round3);
            font-weight: 500;
            backdrop-filter: blur(30px);
            font-size: 1.2rem;
            box-shadow: var(--super-soft-shadow);
        }
    }
}

.pageWrap {
    overflow: scroll;
    height: 80vh;
    padding-bottom: 5rem;
}

.horizontalSection {
    display: flex;
    flex-direction: row;

    * {
        margin-right: 2rem;
    }

    :last-child {
        margin-right: 0;
    }
}