.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: var(--grey-light);
    border-radius: var(--round2);
    height: 9rem;overflow: hidden;

    .imgWrap {
        height: 100%;

        img {
            height: 100%;
        }
    }

    .metaWrap {
        display: flex;
        flex-direction: column;
        width: 80%;
        padding: 1.55rem 1.5rem;

        .category {
            background-color: var(--green-strong);
            width: fit-content;
            padding: .2rem .7rem;
            border-radius: var(--round);
            color: white;
            font-weight: 700;
        }

        .date {
            color: var(--grey);
            font-size: .9rem;
            margin-top: 1.2rem;
        }

        .title {
            margin-top: .3rem;
            font-weight: 700;
            // font-size: 1.3rem;
        }
    }
}

.botWrap {
    margin-top: 2rem;
    background-color: var(--grey-light);
    border-radius: var(--round);
    padding: 1rem 1.5rem;
    width: 100%;

    .article {
        max-height: 16rem;
        overflow-y: scroll;
        white-space: pre-line;

        .subTitle {
            font-weight: 500;
        }
    }
}