@import './styles/themes/functions';

@font-face {
    font-family: 'AzoSans';
    src: url('./assets//fonts/AzoSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'AzoSans';
    src: url('./assets//fonts/AzoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AzoSans';
    src: url('./assets//fonts/AzoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'AzoSans';
    src: url('./assets//fonts/AzoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AzoSans';
    src: url('./assets//fonts/AzoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'AzoSans';
    src: url('./assets//fonts/AzoSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

:root {
    font-size: 15px;
    --black-light: #242424;
    --grey-light: #F4F4F4;
    --grey-medium: #898989;
    --grey: #707070;
    --green: #A1F8A6;
    --green-strong: #5DBA55;
    --red: #F8A1A1;

    --round: .5rem;
    // --round2: .8rem;
    --round2: 1.3rem;
    --round3: .8rem;

    --field-height: 2.7rem;

    --indent1: 1rem;
    --indent2: 1.5rem;

    --breakpoint: 768px;

    --page-border: 4rem;
    --page-top-pad: 2.5rem;

    --btn-border: 1.1rem;
    --gentle-border: .6rem;

    --drop-pad: 1rem 1.4rem;

    --txt-border: .9rem;

    --table-border: 1.5rem;
    --modal-border: 2rem;

    --soft-shadow: 0 .1rem .6rem rgba(#000, .5);
    --soft-shadow-right: 0 .1rem .6rem rgba($color: #000, $alpha: .32);
    --super-soft-shadow: 0 0 3.125rem rgba(0,0,0, 0.04);
    --super-soft-shadow-small: 0 0 2rem rgba(0,0,0, 0.03);
    --blur: blur(30px);
}

* {
    font-family: 'AzoSans';
    box-sizing: border-box;
    transition: all .5s;
    color: var(--black-light)
}

body {
    min-height: 100vh;
}

.App {
    text-align: center;
}

img {
    object-fit: contain;
}

.reveal {
    opacity: 1;
}

.stealth {
    display: none;
}

.bigger-title {
    // height
    font-size: 1.9rem;
    font-weight: 700;
    color: var(--black-light);
}

.stat {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
}

.stat2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
}

.stat3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2.5rem;
}

.blur {
    filter: blur(5px)
}

.blurLock {
    height: 100vh;
    overflow: hidden;
}

.bgOverlay {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 9999;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(color(black), .44);
    opacity: 0;
}

.reveal {
    opacity: 1;
}

.textarea {
    padding: 0;
    padding-left: .75rem;
    margin-top: -.2rem;
}

h1 {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
}

$mobile-breakpoint: 768px;
@media (max-width: $mobile-breakpoint) {

    :root {
        font-size: 14px;
    }

    .stat {

    }

    .stat2 {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        margin-bottom: 4rem;
    }

    .stat3 {
        column-gap: 1rem;
    }
}