.imgBigWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 
    margin-bottom: 2rem;
    .imgWrap {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 10rem;
        padding: 1rem;
        background-color: rgba(#FFC0CB, .7);
        border-radius: var(--round2);

        img {
            width: 100%;
        }
    }
}