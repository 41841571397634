.innerWrap {
    overflow-y: scroll;
    height: 100%;
    margin-bottom: 1rem;
}

.wrap {
    height: 100%;
}

.drop1 {
    z-index: 999;
}

.drop2 {
    z-index: 998;
}