@import '../themes/functions';

.wrap {
    $left-pad: 2.2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    height: 100vh;
    left: 0;
    top: 0;
    padding: var(--page-top-pad) 0 ;
    width: 12rem;

    .top {
        padding: 0 $left-pad;
        width: 100%;

        .logo {
            width: 3rem;
            height: 3rem;
            justify-self: start;
        }
    }

    .bot {
        width: 100%;

        .item {
        
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: $left-pad;
            text-align: left;
            width: 100%;
            height: 3.5rem;
        
            .img {
                width: 1.3rem;
                margin-bottom: .5rem;
            }
        
            .title {
                font-size: 15px;
                font-family: 'AzoSans';
                font-weight: 500;
            }
        }
        

        .current {
            background-color: color(barelyBlue);

            .selectSlice {
                display: inline-block;
                background-color: color(pastelBlue);
                width: .4rem;
                height: 100%;
            }
        }

        .item:hover {
            background-color: color(barelyBlue);
            cursor: pointer;
        }

        .addMenuBtn {
            $size: 3.5rem;
            width: $size;
            height: $size;
            background-color: color(pastelBlue);
            padding-left: 0;
            margin-left: $left-pad;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            font-size: 1.75rem;
            margin-top: 1.85rem;
            margin-bottom: 2rem;
            p {
                color: white;
                margin-bottom: -.25rem;
                font-family: inherit;
            }
        }

        .addMenuBtn:hover {
            background-color: rgba(color(pastelBlue), $alpha: .7);
            cursor: pointer;
        }
    }

    .foot {

        display: flex;
        align-items: flex-end;
        height: 100%;
        margin-left: 2.2rem;

        .logOutBtn {
            width: 2rem;
            height: fit-content;
            opacity: .3;
        }

        .logOutBtn:hover {
            opacity: .6;
        }
    }
}

.addMenu {
    $y-pad: .95rem;
    $x-pad: 2.5rem;
    $width: 17rem;
    position: relative;
    background-color: color(paneSoft);
    background-color: rgba(#DAE6FD, .6);
    backdrop-filter: blur(7px);
    border-radius: var(--modal-border);
    box-shadow: 0 .1rem .8rem rgba(#000, .07);
    font-size: .95rem;
    font-weight: 500;
    height: 100vh;
    width: $width;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: calc(-1 * $width);

    h1 {
        padding: 4rem 0 0 $x-pad;
        margin-bottom: 5.1rem;
        font-weight: 700;
        text-align: left;
    }

    li {
        text-align: left;
        padding: $y-pad $x-pad;
        // font-weight: bold;
    }

    li:first-child {
        padding-top: $y-pad;
    }

    li:hover {
        cursor: pointer;
        background-color: rgba(#fff, .5);
    }

    // li:last-child {
    //     padding-bottom: $y-pad;
    // }
}

.addMenuOpen {
    left: 0;
}

.newBtn {
    cursor: pointer;
    height: 2.5rem;

    .img {
        margin-bottom: 0
    }
}

.newBtn:hover {
    background-color: var(--grey-light);
}

.analytics {
    width: 1rem;
    // background-color: pink;
}

.item:hover {
    // background-color: var(--grey-light);
    cursor: pointer;
}

.logOutIcon {
    // margin-right: 1rem;
}

.logOutBtn:hover {
    opacity: .8;
}

.stealth {
    display: none;
}

.mobileHeader {
    display: none;
}

$breakpoint-mobile: 768px;
@media (max-width: $breakpoint-mobile) {
    .wrap {
        position: fixed;
        width: 100vw;
        left: -100%;
        z-index: 999;
        justify-content: center;

        .logo {
            display: none;
        }
    }

    .addMenu {
        $width: 100vw;
        font-size: 1.3rem;
        right: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        left: calc(-1 * $width);
        border-radius: 0;

        li {
            margin-bottom: 1.5rem;
            // font-weight: bold;
        }
    }

    .addMenuOpen {
        left: 0;
    }

    .navTop {
        width: 15rem;

        .item {
            height: 4rem;
            margin-bottom: 4rem;

            .img {
                width: 1.75rem;
            }

            .title {
                font-size: 1rem;
            }
        }
    }

    #logOutBtn {
        width: 4rem;
        height: 4rem;
    }

    .menuAcco {
        left: 0;
    }

    .mobileHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // background-color: white;
        width: 100vw;
        height: 3rem;
        position: fixed;
        top: 0;
        padding: 2rem 1.5rem 1rem 1.5rem;
        z-index: 1000;

        .hamWrap {
            display: flex;
            flex-direction: column;
            height: 2rem;
            width: 3rem;

            .hamSlice {
                background: var(--black-light);
                width: 100%;
                height: .3rem;
                margin-bottom: .5rem;
            }

            .hamSlice:nth-child(2) {
                width: 70%;
            }
        }

        .activeHamWrap {

            .hamSlice {
                transform: rotate(45deg);
                margin-bottom: -.3rem;
            }

            .hamSlice:nth-child(2) {
                transform: rotate(-45deg);
                width: 100%;
            }
        }

        .logoWrap {
            height: 100%;

            .logo {
                width: 3rem;
            }
        }
    }
}