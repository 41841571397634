@import '../themes/functions.scss';

.wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: color(paneSoft);
    width: 10rem;
    height: 15rem;
    border-radius: var(--round2);
    padding: 2rem;
    box-shadow: var(--super-soft-shadow);
}