@import '../themes/functions.scss';

.wrap {
    height: var(--field-height);
    background-color: color(black);
    border-radius: var(--btn-border);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    width: 100%;
    padding: 0 3rem;
    cursor: pointer;
    // transition: all .5s;
    box-shadow: var(--super-soft-shadow-small);
}

.fit {
    width: fit-content;
}

.wrap:hover {
    opacity: .9;
}

.disabled {
    background-color: var(--grey);
    cursor: default;
}