@import '../themes/functions.scss';

.alertOverlay {
    z-index: 10000;
}

.wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(color(white), .45);
    border-radius: var(--modal-border);
    min-width: 25rem;
    height: fit-content;
    padding: 1.75rem 2.2rem;
    justify-content: space-between;
    backdrop-filter: blur(7px);
    z-index: 99999;

    .top {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;

        .closeBtn {
            font-size: 1.2rem;
            font-weight: 700;
            cursor: pointer;
        }

        .closeBtn:hover {
            opacity: .6;
        }
    }

    button {
        $size: 2.5rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        border-radius: var(--gentle-border);
        background-color: rgba(color(white), .6);
        box-shadow: var(--soft-shadow);
    }

    .mid {
        text-align: center;
        margin-bottom: 1rem;
    }

    .bottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
    }

    .unoBtnBottom {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 5rem;
    }
}