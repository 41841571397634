.wrap {
    position: absolute;
    border-radius: var(--round);
    top: 0;
    left: 0;
    width: fit-content;
    height: var(--field-height);
    padding: .5rem .7rem;
    background-color: #8BEB92;
    opacity: 0;
    transition: opacity .3s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}