@import '../themes/functions';

.wrap {
    height: calc(1.5 * var(--field-height));
    width: 100%;
    background-color: color(paneSoft);
    border-radius: var(--txt-border);
    border-width: .17rem;
    padding: .5rem 1.1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
    justify-content: space-between;
}

.img {
    height: 100%;
}

.input {
    width: 100%;
    background-color: rgba(0,0,0,0);
    border: 0;
    font-family: 'AzoSans';
    font-weight: 500;
    padding: 0;
    color: var(--black-light);
}

.input:focus {
    outline: none;
    box-shadow: none;
}

.textArea {
    margin-top: .1rem;
    height: 100%;
}

.iconWrap {
    width: 1.6rem;
    display: flex;
    justify-content: center;
    height: 1rem;
}

.email {
    padding: .1rem 0;
}

.label {
    font-size: .8rem;
    font-weight: 400;
    color: var(--black-light);
}

.focus {
    border-color: #89CFF0;   
}

.invalid {
    border-color: var(--red);
}

.bigBox {
    height: 15rem;
}