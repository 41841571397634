.innerWrap {
    overflow-y: scroll;
    height: 100%;
    margin-bottom: 1rem;

    .versionWrap {
        height: var(--field-height);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: .6rem 0;
        
        .left {
            width: 75%;
    
            .advancedWrap {
                display: flex;
                flex-direction: row;
    
                .txtWrap {
                    width: 50%;
                    margin-right: 1rem;
                }
    
                .versionNo {
                    margin-right: 1rem;
                }
            }
    
            .simpleWrap {
                display: inline-block;
                width: 8rem;
            }
        }
    
        .right {
            p {
                height: 100%;
                cursor: pointer;
                font-weight: 500;
                padding-right: 1rem;
                // text-decoration: underline;
                transition: .3s ease-in;
            }
    
            p:hover {
                opacity: .7;
            }
        }
    }
}

.wrap {
    height: 100%;
    overflow: visible;
}

.drop1 {
    z-index: 999;
}

.drop2 {
    z-index: 998;
}