@import '../themes/functions.scss';

.wrap {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: -100%;
    height: 100vh;
    width: 28rem;
    padding: 3.5rem 3rem;
    background-color: color(paneSoft);
    z-index: 999;
    box-shadow: 5px 0.2rem 0.4rem rgba(0, 0, 0, 1);
    overflow-y: scroll;
    backdrop-filter: blur(2px);
    border-radius: var(--modal-border) 0 0 var(--modal-border);
    transition: all 1s ease;
}

.wrap.active {
    right: 0;
}

.container {
    position: fixed;
    opacity: 0;
    top: -100%;
    transition: none;
    transition: opacity .3s ease-in;
    display: none;
    backdrop-filter: blur(2px);
}

.container.active {
    opacity: 1;
    top: 0;
    display: block;
}

@media (max-width: 768px) {
    .wrap {
        padding: 3.5rem 3rem;
        width: 90vw;
        padding-bottom: 10rem;
    }

    .wrap.active {
        // right: 0;
    }
}